<!-- coordinate_pickup 坐标拾取插件-->
<template>
    <div>
        <el-dialog
            ref="dialog"
            class="coordinate_pickup"
            :title="title"
            :visible.sync="showPickup"
            width="50%"
            :modal-append-to-body="false"
            :append-to-body="true"
        >
            <div class="latlngPart flex">
                <span>经度：</span>
                <el-input
                    v-model="latlng.lng"
                    placeholder="请输入内容"
                    size="small"
                    :disabled="!editState"
                    @input="changeLng()"
                ></el-input>
                <span style="margin-left: 10px">纬度：</span>
                <el-input
                    v-model="latlng.lat"
                    placeholder="请输入内容"
                    size="small"
                    @input="changeLat()"
                    :disabled="!editState"
                ></el-input>
                <div @click="submit()" class="btn pointer" v-if="editState">
                    确认
                </div>
            </div>
            <div class="mapbox">
                <Mymap :mapData="mapData" @initmap="initmap"></Mymap>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { derectionData } from "@libs/dailyInspectAreaList";
import { mapActions } from "vuex";
export default {
    name: "coordinatePickup",
    components: {},
    props: {
        editState: {
            type: Boolean,
            default() {
                return true;
            },
        },
        showPickupP: Boolean,
        coordinate: {
            type: String,
            default: "",
        },
        title: {
            default: "坐标拾取",
        },
        icon: {
            default: () =>
                window.L.divIcon({
                    className: "my-div-icon",
                    html: '<div style="width:16px;height:16px;background:#23468c;color:#fff;border-radius:50%;line-height: 16px;"></div>',
                }),
        },
    },
    data() {
        return {
            derectionData,
            showPickup: false,
            myMap: null,
            latlng: { lat: 0, lng: 0 },
            mapData: {
                areaState: true,
                dxtState: true,
                maxZoom: 20,
                // mapTypeBtn: false,
            },
            markerPopup: null,
            xcqLabelGroup: null,
            layerGroup: null,
        };
    },
    computed: {},
    watch: {
        showPickup: {
            immediate: false,
            handler() {
                this.$emit("update:showPickupP", this.showPickup);
            },
        },
        showPickupP: {
            immediate: true,
            handler() {
                this.showPickup = this.showPickupP;
            },
        },
        coordinate: {
            immediate: true,
            handler() {
                if (this.myMap) {
                    this.drawMarker();
                }
            },
        },
    },
    mounted() {
        //marker 弹出框
        this.markerPopup = window.L.popup({
            closeOnClick: false,
            closeButton: false,
            offset: [0, 0],
            className: "markerPopup",
        });
    },
    methods: {
        ...mapActions(["getVectorStyle"]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        // 初始化地图
        initmap(myMap) {
            this.myMap = myMap;
            this.layerGroup = window.L.layerGroup().addTo(this.myMap);
            this.drawMarker();
            this.dxtGroup = window.L.layerGroup().addTo(this.myMap); //地形图
            this.xcqLabelGroup = window.L.layerGroup().addTo(this.myMap); //巡查区
            this.myMap.on("click", (event) => {
                if (this.editState) {
                    let lng = Number(event.latlng.lng).toFixed(8);
                    let lat = Number(event.latlng.lat).toFixed(8);
                    this.latlng.lng = lng;
                    this.latlng.lat = lat;
                    let value = `${lng},${lat}`;
                    this.$emit("update:coordinate", value);
                }
            });
            // this.myMap.on("mousemove", (event) => {
            //     this.latlng.lng = event.latlng.lng.toFixed(8);
            //     this.latlng.lat = event.latlng.lat.toFixed(8);
            // });
        },
        changeLng() {
            let lng = Number(this.latlng.lng).toFixed(8);
            let value = `${lng},${this.latlng.lat}`;
            this.$emit("update:coordinate", value);
        },
        changeLat() {
            let lat = Number(this.latlng.lat).toFixed(8);
            let value = `${this.latlng.lng},${lat}`;
            this.$emit("update:coordinate", value);
        },
        drawMarker() {
            let group = this.layerGroup;
            this.clearLayersGroup(group);
            if (this.coordinate) {
                let lng = this.coordinate.split(",")[0];
                let lat = this.coordinate.split(",")[1];
                window.L.marker([lat, lng], {
                    icon: this.icon,
                }).addTo(group);
                this.$emit("initMarker");
                this.latlng.lng = lng;
                this.latlng.lat = lat;
            } else {
                this.latlng.lng = "";
                this.latlng.lat = "";
            }
        },
        submit() {
            let lng = Number(this.latlng.lng).toFixed(8);
            let lat = Number(this.latlng.lat).toFixed(8);
            let value = `${lng},${lat}`;
            this.$emit("update:coordinate", value);
            this.$emit("clickPoint");
            this.showPickup = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.coordinate_pickup {
    .latlngPart {
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
        span {
            line-height: 30px;
            margin-right: 10px;
            font-weight: 700;
        }
        .el-input {
            width: calc(45% - 57px);
            height: 30px;
        }
        .btn {
            width: 80px;
            height: 33px;
            line-height: 33px;
            background: #3a8ee6;
            border-color: #3a8ee6;
            color: #fff;
            border: 1px solid #dcdfe6;
            border-radius: 5px;
            margin-left: 10px;
        }
    }
    .mapbox {
        height: 600px;
        width: 100%;
        position: relative;
    }
}
</style>
