<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">古树名木</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="乡镇" prop="XZ">
                    <el-input
                        v-model="formdata.dataDic.XZ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="村名" prop="CM">
                    <el-input
                        v-model="formdata.dataDic.CM"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="组名" prop="ZM">
                    <el-input
                        v-model="formdata.dataDic.ZM"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="小地名" prop="XDM">
                    <el-input
                        v-model="formdata.dataDic.XDM"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="经纬度" prop="jwd">
                    <div class="flex">
                        <el-tooltip
                            class="item"
                            effect="light"
                            content="请填写正确的经纬度坐标，经度和纬度用英文的逗号隔开，如：118.056786,24.435336或 118.071978,24.444005；或者点击“坐标拾取”按钮，直接获取经度坐标"
                            placement="bottom"
                        >
                            <el-input
                                v-model="formdata.dataDic.jwd"
                                placeholder="请输入经纬度"
                                onkeydown="if(event.keyCode==32) return false"
                                :disabled="!isEdit"
                                style="width: 442px; margin-right: 10px"
                            ></el-input>
                        </el-tooltip>
                        <el-button size="small" @click="showPickup = true"
                            >坐标拾取</el-button
                        >
                    </div>
                </el-form-item>
                <el-form-item label="海拔" prop="HB">
                    <el-input
                        v-model="formdata.dataDic.HB"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">米</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="中文名" prop="SZ_ZWM">
                    <el-input
                        v-model="formdata.dataDic.SZ_ZWM"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item label="别名" prop="SZ_BM">
                    <el-input
                        v-model="formdata.dataDic.SZ_BM"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="拉丁名" prop="SZ_LDM">
                    <el-input
                        v-model="formdata.dataDic.SZ_LDM"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="科名" prop="SZ_KM">
                    <el-input
                        v-model="formdata.dataDic.SZ_KM"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="属名" prop="SZ_SM">
                    <el-input
                        v-model="formdata.dataDic.SZ_SM"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="树龄" prop="SMXZ_SL">
                    <el-input
                        v-model="formdata.dataDic.SMXZ_SL"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">年</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="树高" prop="SMXZ_SG">
                    <el-input
                        v-model="formdata.dataDic.SMXZ_SG"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="胸径" prop="SMXZ_XJ">
                    <el-input
                        v-model="formdata.dataDic.SMXZ_XJ"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">cm</template>
                    </el-input>
                </el-form-item>

                <el-form-item label="平均冠幅" prop="SMXZ_PJGF">
                    <el-input
                        v-model="formdata.dataDic.SMXZ_PJGF"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="东西冠幅" prop="SMXZ_DXGF">
                    <el-input
                        v-model="formdata.dataDic.SMXZ_DXGF"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="南北冠幅" prop="SMXZ_NBGF">
                    <el-input
                        v-model="formdata.dataDic.SMXZ_NBGF"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="生长势" prop="SZS">
                    <el-input
                        v-model="formdata.dataDic.SZS"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="权属" prop="QS">
                    <el-input
                        v-model="formdata.dataDic.QS"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="管护单位或个人" prop="GHDW">
                    <el-input
                        v-model="formdata.dataDic.GHDW"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="管护内容" prop="GHNR">
                    <el-input
                        v-model="formdata.dataDic.GHNR"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="树木特殊状况描述" prop="SMTSZKMS">
                    <el-input
                        v-model="formdata.dataDic.SMTSZKMS"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="调查人" prop="DCR">
                    <el-input
                        v-model="formdata.dataDic.DCR"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="调查日期" prop="DCRQ">
                    <el-date-picker
                        v-model="formdata.dataDic.DCRQ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>

            <coordinatePickup
                :editState="isEdit"
                :showPickupP.sync="showPickup"
                :coordinate.sync="formdata.dataDic.jwd"
            ></coordinatePickup>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
import coordinatePickup from "@comp/map/coordinate_pickup";
export default {
    name: "zr_dqzl",
    components: {
        coordinatePickup,
    },
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            showPickup: false,
            formdata: {
                itemCode: "306",
                dataDic: {
                    XZ: "",
                    CM: "",
                    ZM: "",
                    XDM: "",
                    DJ: "",
                    BW: "",
                    HB: "",
                    SZ_ZWM: "",
                    SZ_BM: "",
                    SZ_LDM: "",
                    SZ_KM: "",
                    SZ_SM: "",
                    SMXZ_SL: "",
                    SMXZ_SG: "",
                    SMXZ_XJ: "",
                    SMXZ_PJGF: "",
                    SMXZ_DXGF: "",
                    SMXZ_NBGF: "",
                    SZS: "",
                    QS: "",
                    GHDW: "",
                    GHNR: "",
                    SMTSZKMS: "",
                    DCR: "",
                    DCRQ: "",
                    jwd: "",
                },
            },
            SGLXList: [],
        };
    },
    mounted() {
        // this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            // let res = await this.getAllEnumInfobytableID({
            //     bid: "1303",
            // });
            // let enumList = res || [];
            // enumList.forEach((v) => {
            //     if (v.COLUMNID === "JB") {
            //         this.SGLXList = v.dataItems;
            //     }
            // });
        },
        GetDataNext() {
            this.formdata.dataDic.jwd = `${this.formdata.dataDic.DJ},${this.formdata.dataDic.BW}`;
        },
    },
};
</script>

<style>
</style>
